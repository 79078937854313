a {
    display: flex;
    text-decoration: none;
    color: inherit;
    transition: color 0.1s ease;
}

a h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

a p {
    font-size: 1rem;
    color: var(--text-color);
    opacity: 0.5;
}

img {
    color: var(--text-color);
}

.logo {
    display: flex;
    width: 30px;
    height: 70px;
    justify-content: center;
    margin-right: 1rem;
}

.section-link {
    padding: 20px;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background-color: transparent;
    transition: background-color 0.1s ease, transform 0.1s ease, color 0.1s ease;
    max-width: 50em;
    white-space: normal;
    word-wrap: break-word;
    color: inherit;
}

.section-link:hover {
    background-color: rgba(50, 50, 50, 0.5);
    color: rgb(144, 245, 244);
}

.svg-logo {
    width: 1.5rem;
    height: 1.5rem;
    transition: fill 0.1s ease, opacity 0.1s ease;
    fill: none;
}

.svg-logo.hovered {
    fill: rgb(144, 245, 244) !important;
    opacity: 1;
}

.experienceTitle,
.experienceDescription {
    font-size: 1.1rem;
}

.experienceDescription {
    margin: 0;
}

.sectionContent {
    padding-left: 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}