.personal-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color, rgb(255, 255, 255));
    padding: 8px;
    border-radius: 16px;
    transition: all .3s ease;
}

.personal-link:hover svg {
    transform: scale(1.3);
    transition: all .3s ease;
}

.standard-icon-size {
    width: 1.5rem;
    height: 1.5rem;
}