/* Container for header */
.header-container {
    padding: 0 2rem;
    margin: 6rem auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 50em;
    text-align: center;
}

/* Inline block style */
.inline-block {
    display: block;
    text-align: center;
    justify-content: center;
}

/* Container for animations */
.animation-container {
    line-height: 1.5em;
    /* Ensures consistent line height across animations */
}

/* Link style */
a {
    text-decoration: none;
    /* Remove underline for links */
}

/* Header style */
.header-container h2 {
    color: var(--text-color);
    text-align: center;
    margin: 0;
    /* Reset default margin */
}

/* Text decoration with underline */
.text-decoration {
    display: inline-block;
    position: relative;
}

/* Underline effect after text */
.text-decoration::after {
    content: '';
    width: 100%;
    height: 30px;
    background-image: url('../../public/images/underline.svg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -1rem;
    left: 0;
}

/* Animated text style */
.text-animation {
    display: inline-block;
    width: 10.24em;
    height: auto;
    overflow: hidden;
    vertical-align: bottom;
    text-align: left;
    /* Align text to the left */
    animation: blink-caret 0.75s step-end infinite;
    color: var(--text-decoration);
}

/* Keyframes for appearing animation */
@keyframes appearIn {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Keyframes for moving in animation */
.moveIn {
    animation: moveIn 1s ease-out forwards;
}