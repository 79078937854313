/* Overarching Container for the Landing Page */
.scroll-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: var(--particle-background);
    overflow: hidden;
}

/* Styling for Background of Landing Page */
.particlesStyle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: '#000';
}

/* Styling for The Landing Page Title */
.landing-page-header {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-left: 3rem;
    position: absolute;
    z-index: 1;
}

.landing-page-header h1 {
    margin: 0px;
}

/* Personal Links */
.landing-page-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    gap: 0.5rem;
    bottom: 2rem;
    right: 3rem;
}

.landing-page-header h1 {
    font-size: 4.5rem;
    color: var(--text-color);
}

.landing-page-title p {
    font-size: 1.5rem;
    color: var(--text-color);
}