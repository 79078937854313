/* Handles Animation of the Experience Container Appearing */
.experience-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.experience-container.visible {
    opacity: 1;
    transform: translateY(0);
}