/* All Elements of the Header */
.header {
    display: flex;
    top: 0;
    left: 0;
    z-index: 10;
    flex-direction: row;
    box-sizing: border-box;
    width: calc(100%);
    padding: 1.5rem 3rem;
    justify-content: space-between;
    color: var(--text-color-dark);
    font-weight: 500;
}

html {
    overflow-y: auto;
}

.header-container {
    max-width: 50rem;
}

/* Header Flex Component */
.header-left,
.header-right {
    display: flex;
    flex-direction: row;
}

/* Left Header */
.header-left {
    display: flex;
    opacity: 0;
}

.first-name,
.last-name {
    display: inline-block;
    color: var(--text-color);
}

.collapse-on-hover {
    display: inline-block;
    white-space: pre;
    overflow: hidden;
    max-width: 1em;
    transition: all .3s ease;
}

.logo-letter-hide {
    transform-origin: 0 50%;
    transition: all .3s ease;
    opacity: 0;
}

/* Hover Effects */
.transformName {
    text-align: center;
    margin: 0;
    line-height: 0;
}

/* Header Right */
.header-right {
    gap: 60px;
}

.header-right div,
.header-right a {
    color: var(--text-color);
    transition: color 0.3s ease;
}

.header-right div:hover,
.header-right a:hover {
    transform: scale(1.1);
}