h3 {
    align-items: left;
    color: white;
}

h3 span {
    opacity: 0.5;
}

.ExperienceSection {
    padding-bottom: 6rem;
}

.sectionTitle {
    margin-bottom: 2rem;
}